::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  border-radius: 9999px;
  background-color: #dedede;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #b7b7b7;
}

html {
  background-color: #fff;
  min-height: 100vh;
}

body {
  font-family: "Euclid Circular A", "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  user-select: none;
  min-height: 100vh;

  background: #f1f2f3 url(/src/Assets/Img/background.svg) center top no-repeat;
  background-size: contain;
}


@media (max-width: 575px) {
  body {
    font-size: 0.75rem;
    background-size: 200%;
  }
}

.w-1 {
  width: 1%;
}

.modal-backdrop.show {
  opacity: 0.2;
}

svg.shadow,
img.shadow {
  box-shadow: none !important;
  -webkit-filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
}

@media (max-width: 1200px) {
  .display-1 {
    font-size: calc(1.725rem + 5.7vw) !important;
  }

  .display-2 {
    font-size: calc(1.675rem + 5.1vw) !important;
  }

  .display-3 {
    font-size: calc(1.575rem + 3.9vw) !important;
  }

  .display-4 {
    font-size: calc(1.475rem + 2.7vw) !important;
  }

  h1,
  .h1 {
    font-size: calc(1.35rem + 1.2vw) !important;
  }

  h2,
  .h2 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }

  h3,
  .h3 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }

  h4,
  .h4 {
    font-size: calc(1.25rem + 0vw) !important;
  }
}

.animation-bounce {
  animation: animBounce 1s ease-in-out 0s infinite alternate;
}
@keyframes animBounce {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-5px);
  }
}

.animation-zoom {
  animation: animZoom 5s linear 0s infinite;
}
@keyframes animZoom {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  to {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
