@font-face {
  font-family: 'Euclid Circular A';
  src: url('/src/Assets/Fonts/EuclidCircularA-Bold.eot');
  src: local('/src/Assets/Fonts/Euclid Circular A Bold'), local('/src/Assets/Fonts/EuclidCircularA-Bold'),
    url('/src/Assets/Fonts/EuclidCircularA-Bold.eot?#iefix') format('embedded-opentype'),
    url('/src/Assets/Fonts/EuclidCircularA-Bold.woff2') format('woff2'),
    url('/src/Assets/Fonts/EuclidCircularA-Bold.woff') format('woff'),
    url('/src/Assets/Fonts/EuclidCircularA-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('/src/Assets/Fonts/EuclidCircularA-Light.eot');
  src: local('/src/Assets/Fonts/Euclid Circular A Light'), local('/src/Assets/Fonts/EuclidCircularA-Light'),
    url('/src/Assets/Fonts/EuclidCircularA-Light.eot?#iefix') format('embedded-opentype'),
    url('/src/Assets/Fonts/EuclidCircularA-Light.woff2') format('woff2'),
    url('/src/Assets/Fonts/EuclidCircularA-Light.woff') format('woff'),
    url('/src/Assets/Fonts/EuclidCircularA-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('/src/Assets/Fonts/EuclidCircularA-BoldItalic.eot');
  src: local('/src/Assets/Fonts/Euclid Circular A Bold Italic'), local('/src/Assets/Fonts/EuclidCircularA-BoldItalic'),
    url('/src/Assets/Fonts/EuclidCircularA-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('/src/Assets/Fonts/EuclidCircularA-BoldItalic.woff2') format('woff2'),
    url('/src/Assets/Fonts/EuclidCircularA-BoldItalic.woff') format('woff'),
    url('/src/Assets/Fonts/EuclidCircularA-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('/src/Assets/Fonts/EuclidCircularA-LightItalic.eot');
  src: local('/src/Assets/Fonts/Euclid Circular A Light Italic'), local('/src/Assets/Fonts/EuclidCircularA-LightItalic'),
    url('/src/Assets/Fonts/EuclidCircularA-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('/src/Assets/Fonts/EuclidCircularA-LightItalic.woff2') format('woff2'),
    url('/src/Assets/Fonts/EuclidCircularA-LightItalic.woff') format('woff'),
    url('/src/Assets/Fonts/EuclidCircularA-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('/src/Assets/Fonts/EuclidCircularA-Regular.eot');
  src: local('/src/Assets/Fonts/Euclid Circular A Regular'), local('/src/Assets/Fonts/EuclidCircularA-Regular'),
    url('/src/Assets/Fonts/EuclidCircularA-Regular.eot?#iefix') format('embedded-opentype'),
    url('/src/Assets/Fonts/EuclidCircularA-Regular.woff2') format('woff2'),
    url('/src/Assets/Fonts/EuclidCircularA-Regular.woff') format('woff'),
    url('/src/Assets/Fonts/EuclidCircularA-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('/src/Assets/Fonts/EuclidCircularA-SemiBold.eot');
  src: local('/src/Assets/Fonts/Euclid Circular A SemiBold'), local('/src/Assets/Fonts/EuclidCircularA-SemiBold'),
    url('/src/Assets/Fonts/EuclidCircularA-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('/src/Assets/Fonts/EuclidCircularA-SemiBold.woff2') format('woff2'),
    url('/src/Assets/Fonts/EuclidCircularA-SemiBold.woff') format('woff'),
    url('/src/Assets/Fonts/EuclidCircularA-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('/src/Assets/Fonts/EuclidCircularA-Medium.eot');
  src: local('/src/Assets/Fonts/Euclid Circular A Medium'), local('/src/Assets/Fonts/EuclidCircularA-Medium'),
    url('/src/Assets/Fonts/EuclidCircularA-Medium.eot?#iefix') format('embedded-opentype'),
    url('/src/Assets/Fonts/EuclidCircularA-Medium.woff2') format('woff2'),
    url('/src/Assets/Fonts/EuclidCircularA-Medium.woff') format('woff'),
    url('/src/Assets/Fonts/EuclidCircularA-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('/src/Assets/Fonts/EuclidCircularA-Italic.eot');
  src: local('/src/Assets/Fonts/Euclid Circular A Italic'), local('/src/Assets/Fonts/EuclidCircularA-Italic'),
    url('/src/Assets/Fonts/EuclidCircularA-Italic.eot?#iefix') format('embedded-opentype'),
    url('/src/Assets/Fonts/EuclidCircularA-Italic.woff2') format('woff2'),
    url('/src/Assets/Fonts/EuclidCircularA-Italic.woff') format('woff'),
    url('/src/Assets/Fonts/EuclidCircularA-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('/src/Assets/Fonts/EuclidCircularA-SemiBoldItalic.eot');
  src: local('/src/Assets/Fonts/Euclid Circular A SemiBold Italic'), local('/src/Assets/Fonts/EuclidCircularA-SemiBoldItalic'),
    url('/src/Assets/Fonts/EuclidCircularA-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('/src/Assets/Fonts/EuclidCircularA-SemiBoldItalic.woff2') format('woff2'),
    url('/src/Assets/Fonts/EuclidCircularA-SemiBoldItalic.woff') format('woff'),
    url('/src/Assets/Fonts/EuclidCircularA-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('/src/Assets/Fonts/EuclidCircularA-MediumItalic.eot');
  src: local('/src/Assets/Fonts/Euclid Circular A Medium Italic'), local('/src/Assets/Fonts/EuclidCircularA-MediumItalic'),
    url('/src/Assets/Fonts/EuclidCircularA-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('/src/Assets/Fonts/EuclidCircularA-MediumItalic.woff2') format('woff2'),
    url('/src/Assets/Fonts/EuclidCircularA-MediumItalic.woff') format('woff'),
    url('/src/Assets/Fonts/EuclidCircularA-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
