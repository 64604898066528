.card,
.modal-content,
.popover {
  background: rgba(219, 244, 255, .2);
  box-shadow: 0 8px 32px 0 rgb(0 138 198 / 15%);
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}
.card .card,
.modal-content .card,
.popover .card {
  background: rgba(255, 255, 255, .1);
  box-shadow: 0 0 12px 0 rgb(0 138 198 / 15%);
}
.modal-content {
  background: rgba(255, 255, 255, .9);
}
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .card,
  .modal-content,
  .popover {
    background: rgba(219, 244, 255, .2);
    box-shadow: 0 8px 32px 0 rgb(0 138 198 / 15%);
    backdrop-filter: blur( 16px );
    -webkit-backdrop-filter: blur( 16px );
  }
  .modal-content {
    background: rgba( 255, 255, 255, 0.5 );
  }
  .popover {
    box-shadow: 0 8px 32px 0 rgba(130, 130, 130, .5);
  }
}
.popover .card,
.admin-area .card {
  background: #fff;
}
.card:not(.popover) {
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}
.card .card-header {
  border-bottom: none;
  border-radius: 0;
  background: rgb(232 230 245);
  background: linear-gradient(180deg, rgb(231 230 246) 0%, rgb(255 255 255 / 0%) 100%);
}

.row-products .card-product {
  position: relative;
  cursor: pointer;
  transition: all ease-in-out .5s !important;
}
.row-products .card-product:hover {
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
}
.row-products .card-product a {
  color: inherit;
}
.row-products .card-product .product-led {
  position: absolute;
  z-index: 1;
  right: 5px;
  font-weight: bold;
  color: #198754;
}