.text-primary {
  color: #008ac6 !important;
}

.btn-primary {
  color: #fff;
  background-color: #008ac6;
  border-color: #008ac6;
}
.btn-outline-primary {
  color: #008ac6;
  border-color: #008ac6;
}

.bg-primary {
  background-color: #008ac6 !important;
}

code {
  color: inherit;
}

.modal-content {
  overflow: hidden;
}

.list-group-item {
  padding: 0.25rem 0;
  border: none;
}

.fw-bold {
  font-weight: 600 !important;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: #dcdcdc;
}

.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after,
.bs-popover-bottom > .popover-arrow::after {
  top: 3px;
}
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before,
.bs-popover-bottom > .popover-arrow::before {
  top: 2px;
}
