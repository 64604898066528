.preloader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #008ac6;
  overflow: hidden;
}
.preloader > div {
  margin: 10vh auto 0;
  color: #fff;
  text-align: center;
  display: block;
}